// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: inter;
  /* background-color: #f8f8f8 !important; */
}
.app-div {
  background-color: #f8f8f8;
  min-height: 100vh;
}
hr {
  color: rgba(200, 203, 217, 0.5);
  height: 0.5px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,0CAA0C;AAC5C;AACA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;AACA;EACE,+BAA+B;EAC/B,aAAa;AACf","sourcesContent":["* {\n  font-family: inter;\n  /* background-color: #f8f8f8 !important; */\n}\n.app-div {\n  background-color: #f8f8f8;\n  min-height: 100vh;\n}\nhr {\n  color: rgba(200, 203, 217, 0.5);\n  height: 0.5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
