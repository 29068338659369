import * as React from "react";
import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import "./App.css";
const Login = lazy(() => import("./view/Login/Login"));
const Dashboard = lazy(() => import("./view/Dashboard/index"));

function App() {
  return (
    <div className="app-div">
      <Routes>
        <Route
          path="/dashboard"
          element={
            <Suspense
              fallback={
                <div style={{ margin: "45vh 10vw" }}>
                  <LinearProgress
                    // style={{
                    //   color: "#27ae60",
                    //   backgroundColor: "#27ae60",
                    //   fill: "#27ae60",
                    // }}
                    color="success"
                  />
                  <div>Wait while we are building dashboard...</div>
                </div>
              }
            >
              {" "}
              <Dashboard />
            </Suspense>
          }
        />
        <Route
          path="/login"
          element={
            <Suspense
              fallback={
                <div style={{ margin: "45vh 10vw" }}>
                  <LinearProgress
                    // style={{
                    //   color: "#27ae60",
                    //   backgroundColor: "#27ae60",
                    //   fill: "#27ae60",
                    // }}
                    color="success"
                  />
                  <div>Wait while we are loading...</div>
                </div>
              }
            >
              {" "}
              <Login />
            </Suspense>
          }
        />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </div>
  );
}

export default App;
